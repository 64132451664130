<template>
  <div class="control-page">
    <img  @click="$router.push('/')" class="logo" src="../../assets/img/header/hx-logo.svg"></img>
    <button class="index">http://www.scaniov.com</button>
    <p class="tips">温馨提示：请前往PC端体验完整功能</p>
    <button @click="$router.push('/')" class="backIndex">回到首页</button>
   <footer>
       


    <p class="footerContent">Copyright © 2019-2030 广东中科智能区块链技术有限公司 </p>
    <p class="footerContent">All Rights Reserved 粤ICP备19156622号</p>
    <p class="footerContent">国家网信办区块链备案：粤网信备44011220826683380011号</p>
   </footer>
  </div>
</template>

<script setup>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.control-page {
  padding-top: 60px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/img/control/bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  .logo {
    width: 180px;
    margin-top: 2rem;
    cursor: pointer;
  }
  .index {
    margin: 3rem 0 1.6rem;
    width: 80%;
    height: 40px;
    border: 1px solid #ececec;
    border-radius: 3px;
  }
  .tips {
    color: #666666;
    margin-bottom: 3rem;
  }
  .backIndex {
    width: 140px;
    height: 40px;
    background-color: #387efd;
    color: #fff;
    border-radius: 3px;
  }
  footer {
    position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    bottom: 20px;
    .footerContent {
      line-height: 22px;
      text-align: center;
      color: #9a9a9a;
      font-size: 12px;
    }
  }
}
</style>